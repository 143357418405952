import { gsap } from 'gsap';
// import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';

class Parallax {
  // Parallax's default travel.
  public DEFAULT_TRAVEL: number = -100;
  // Initialized parallax.
  public parallax: any;
  // Initialized timeline.
  public timeline: any;
  // Parallax's travel. If negative, it moves to opposite direction.
  public travel: number;
  // Start positon
  public start: number = 0;
  // End positon
  public end: number = 0;
  // Animation/scroll duration
  public duration: any = null;
  // Paralax element's height
  public height: number = 0;
  // Is element in viewPort?
  public inViewPort: boolean = false;
  // Intesection observer instead of scroll monitor to track if it's entered
  public observer: IntersectionObserver;
  // Intesection observer to start tracking just before entering and
  // soon after leaving viewport to add 'will-change' to increase performance.
  public changeObserver: IntersectionObserver;

  public $parallax: HTMLElement;
  public parallaxsController: any;
  public i: number;

  /**
   * Creates an instance of Parallax. Assigns class variables and iniaitate Swiper module.
   * @param {HTMLElement} $parallax HTML element of the Parallax's target;
   * @param {*} parallaxsController Parallax's parent initialization funtion
   * @param {number} i Parallax's initializator's place in array
   * @memberof Parallax
   */
  constructor($parallax: HTMLElement, parallaxsController: any, i: number) {
    this.parallaxsController = parallaxsController;
    this.i = i;
    this.$parallax = $parallax;
    this.travel = $parallax.dataset.parallax
      ? Number($parallax.dataset.parallax)
      : this.DEFAULT_TRAVEL;

    this.seek = this.seek.bind(this);

    this.mountParallax();
  }

  /**
   * Start mounting lifecycle - beforeMount, onMount and afterMount hooks.
   *
   * @returns {Parallax} For chaining.
   * @memberof Parallax
   */
  public mountParallax() {
    this.beforeMount();
    this.calculatePosition();
    this.onMount();
    this.afterMount();
    return this;
  }

  /**
   * An actual animation function (GSAP, anime etc) initialization.
   *
   * @returns {Parallax} For chaining.
   * @memberof Parallax
   */
  public calculatePosition() {
    const rect = this.$parallax.getBoundingClientRect();
    const top = rect.top + window.scrollY;

    this.height = rect.height;
    this.start = top < window.innerHeight ? 0 : top - window.innerHeight;
    this.end = top + rect.height + this.travel;
    this.duration = this.end - this.start;

    // const $marker = document.createElement('i');
    // $marker.classList.add('marker');
    // $marker.style.top = `${this.start}px`;
    // $marker.style.height = `${this.duration}px`;
    // document.body.appendChild($marker);
    return this;
  }

  /**
   * An actual animation function (GSAP, anime etc) initialization.
   * Initially set to paused, but should be overriten in every class extensio.
   *
   * @returns {Parallax} For chaining.
   * @memberof Parallax
   */
  public onMount() {
    this.timeline = gsap.timeline({ paused: true }).to(this.$parallax, {
      y: this.travel,
      duration: this.duration,
    });

    this.seek();

    this.observer = new IntersectionObserver(
      entries => (entries[0].isIntersecting ? this.startTracking() : this.stopTracking()),
      { threshold: 0 },
    );

    this.changeObserver = new IntersectionObserver(
      entries =>
        entries[0].isIntersecting ? this.beforeStartTracking() : this.afterStopTracking(),
      { threshold: 0, rootMargin: '50px' },
    );

    this.observer.observe(this.$parallax);

    this.changeObserver.observe(this.$parallax);

    return this;
  }

  public startTracking() {
    window.addEventListener('scroll', this.seek, { capture: false, passive: true });
  }

  public beforeStartTracking() {
    this.$parallax.style.willChange = 'transform';
  }

  public stopTracking() {
    window.removeEventListener('scroll', this.seek);
  }

  public afterStopTracking() {
    this.$parallax.style.willChange = '';
  }

  public seek() {
    const to = window.scrollY - this.start;
    if (to >= 0 && to <= this.duration) this.timeline.seek(to);
    this.timeline.seek(to);
  }

  /**
   * Extra function to call BEFORE mounting parallax.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Parallax} For chaining.
   * @memberof Parallax
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call AFTER mounting parallax.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Parallax} For chaining.
   * @memberof Parallax
   */
  public afterMount() {
    return this;
  }
}

export default Parallax;
