import { gsap } from 'gsap';

import kebabize from '../../toolkit/kebabize';

class Tweener {
  // Micro time (ms) before starting timeline. It is used to ensure everything is painted on window.
  // Also, for toggling 'will-change:' property classes - `before-tween` and `will-tween`.
  public DELAY_BEFORE_START: number = 200;
  // Default duration of single Tweener:
  public DEFAULT_DURATION: number = 750;
  // Speed coefficient to speedup/slow down Tweener.
  public SPEED: number = 1;
  // Initialized tweener.
  public tweener: any;
  // Initialized timeline.
  public timeline: any;
  // Tweener's type.
  public type: string = '';
  // Additional Timeline delay. For suspending concurent tweens
  public suspend: number = 0;

  public $tweener: HTMLElement;
  public tweenersController: any;
  public i: number;

  /**
   * Creates an instance of Tweener. Assigns class variables and iniaitate Swiper module.
   * @param {HTMLElement} $tweener HTML element of the Tweener's target;
   * @param {*} tweenersController Tweener's parent initialization funtion
   * @param {number} i Tweener's initializator's place in array
   * @param {number} suspend Tweener's additional suspend upon initialization
   * @memberof Tweener
   */
  constructor($tweener: HTMLElement, tweenersController: any, i: number, suspend: number = 0) {
    this.tweenersController = tweenersController;
    this.i = i;
    this.$tweener = $tweener;
    this.type = $tweener.dataset.tweener;
    this.suspend = suspend;

    this.onTimelineComplete = this.onTimelineComplete.bind(this);

    this.mountTweener();
  }

  /**
   * Start mounting lifecycle - beforeMount, onMount and afterMount hooks.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public mountTweener() {
    this.beforeMount()
      .onMount()
      .afterMount();
    return this;
  }

  /**
   * An actual animation function (GSAP, anime etc) initialization.
   * Initially set to paused, but should be overriten in every class extensio.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onMount() {
    this.timeline = gsap.timeline({
      defaults: { ease: 'elastic.out(.75, 0.5)' },
      paused: true,
      delay: (this.DELAY_BEFORE_START + this.suspend) / 1000,
      onComplete: this.onTimelineComplete,
    });
    this.addTweens();
    return this;
  }

  /**
   * Add tweens to `Tweener.timeline`.
   * Used for extending in child classes for additional individual cleanup.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public addTweens() {
    return this;
  }

  /**
   * 'auto' add single tween to `Tweener.timeline`.
   * Used to abstract adding animation with `TweenMax`.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public addTween($el, time, props, delay?) {
    if ($el) {
      let previousWillChangeProps = '';
      if ($el.style['will-change']) previousWillChangeProps = `${$el.style['will-change']},`;
      const transformProps = ['x', 'y', 'translateY', 'translateX'];
      const willChangeProps = Object.keys(props)
        .map(prop => (transformProps.indexOf(prop) ? 'transform' : prop))
        .join(',');

      $el.style['will-change'] = previousWillChangeProps + willChangeProps;

      this.timeline.add(
        $el,
        {
          // targets: $el,
          duration: time,
          ...props,
          complete: () => this.cleanUp($el, Object.keys(props)),
        },
        `+=${delay}`,
      );
    }
    return this;
  }

  /**
   * Start the tweener animation. Fired on `scrollmonitor` watcher enter from `TweenerController`.
   * Emit `onTweenerStart()` on parent controller with itself as parameter.
   * Update current tweener - add classes, animate, play with inner HTML here upon opening.
   * `beforeStart()` and `afterStart()` ar more like modifiers to be extended in custom tweeners.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public startTweener() {
    this.beforeStart();
    this.timeline.play();
    this.tweenersController.onTweenerStart(this);
    this.$tweener.removeAttribute('data-tweener-suspend');
    this.afterStart();

    return this;
  }

  /**
   * Extra function to call BEFORE mounting tweener.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public beforeMount() {
    this.$tweener.removeAttribute('data-tweener');
    return this;
  }

  /**
   * Extra function to call AFTER mounting tweener.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public afterMount() {
    this.$tweener.classList.remove('_before-tween');
    return this;
  }

  /**
   * Extra function to call before opening tweener.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public beforeStart() {
    return this;
  }

  /**
   * Extra function to call after opening tweener.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public afterStart() {
    return this;
  }

  /**
   * Remove added styles and data-tweener attriute from element
   *
   * @param {HTMLElement} $el An element from which styles shall be removed.
   * @param {string[]} attributes An array of CSS properties to be removed.
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public cleanUp($el: HTMLElement, props: string[]) {
    const transformProps = ['x', 'y', 'translateY', 'translateX'];
    if (!this.tweenersController.isTweening) {
      if ($el) {
        props.forEach(prop => ($el.style[transformProps.indexOf(prop) ? 'transform' : prop] = ''));
        if (Object.keys($el.dataset).length) {
          for (const key in $el.dataset) {
            if (key.includes('tweener')) $el.removeAttribute(`data-${kebabize(key)}`);
          }
        }
        $el.style['will-change'] = '';
        if (!$el.getAttribute('style')) $el.removeAttribute('style');
      }
    } else {
      setTimeout(() => this.cleanUp($el, props), 2000);
    }
    return this;
  }

  /**
   * Callback after `Tweener.timeline` is complete.
   * Used for extending in child classes for additional individual cleanup.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTimelineEnd() {
    return this;
  }

  /**
   * Callback function, which is called u[on Tweener.timeline completion.
   * Executes some additional code cleanup and emits `onTweenerEnd()` to parent controller.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTimelineComplete() {
    this.tweenersController.onTweenerEnd(this);
    this.$tweener.classList.remove('_should-tween');
    this.onTimelineEnd();
    return this;
  }
}

export default Tweener;
