import Tweener from './Tweener';

class TweenerHero extends Tweener {
  public addTweens() {
    const $visual = this.$tweener.querySelector('[data-tweener-visual]');
    const $letters = this.$tweener.querySelector('#vletters');
    const $circle = this.$tweener.querySelector('#vcircle');
    const $koala = this.$tweener.querySelector('#vkoala');
    const $tagline = this.$tweener.querySelector('[data-tweener-tagline]');
    this.timeline
      .fromTo(
        $visual,
        { rotation: -10, y: 20, opacity: 0 },
        { rotation: 0, y: 0, opacity: 1, duration: 0.5, ease: 'power2.out' },
      )
      .fromTo(
        $letters,
        { rotation: -180, scale: 0, transformOrigin: '50% 55%' },
        { rotation: 0, scale: 1, duration: 2 },
        '-=.3',
      )
      .fromTo(
        $circle,
        { rotation: -20, scale: 0, transformOrigin: '50% 55%' },
        { rotation: 0, scale: 1, duration: 2 },
        '-=1.8',
      )
      .fromTo(
        $koala,
        { rotation: -40, scale: 0, transformOrigin: '50% 55%' },
        { rotation: 0, scale: 1, duration: 2 },
        '-=1.8',
      )
      .from(
        $tagline,
        { width: 0, paddingLeft: 0, paddingRight: 0, duration: 0.8, ease: 'back.out(2.7)' },
        '-=2',
      );
    return this;
  }

  // public onTimelineComplete() {
  //   return this;
  // }
}

export default TweenerHero;
