// SCSS
import '../scss/index.scss';

import turbolinks from 'turbolinks';
import gsap from 'gsap';

// POLYFILLS
// Custom POLYFILLS
import './toolkit/polyfills';
import { fixBody, releaseBody } from './toolkit/fixBody';

// Vendor POLYFILLS and initialization
import 'intersection-observer';
import 'objectFitPolyfill';
import cssVarsPonyfill from 'css-vars-ponyfill';

// Universal MODULES:
import CookiebarJS from './modules/CookiebarJS';
import Player from './modules/Player';
import ModalsController from './modules/modals/ModalsController';
import ParallaxsController from './modules/parallaxs/ParallaxsController';
import TweenersController from './modules/tweeners/TweenersController';

// Various other TOOLS:
import $$ from './toolkit/$$';

let navOpen = document.body.matches('.nav-open');
const $nav = $$('[data-nav]');
const navTimeline =
  window.innerWidth < 769
    ? gsap
        .timeline({ defaults: { ease: 'power3.out' }, paused: true })
        .set($nav, { opacity: 1 })
        .fromTo($nav, { y: '-100%' }, { y: 0, duration: 0.6 })
    : null;

const setAnchors = (url?) => {
  const trim = str => (str.charAt(str.length - 1) === '/' ? str.slice(0, -1) : str);
  const href = url || location.href;
  [].forEach.call($$('a'), $a => {
    $a.classList[trim($a.href) === trim(href) ? 'add' : 'remove']('is-active');
    $a.parentElement.classList[trim($a.href) === trim(href) ? 'add' : 'remove']('is-active');
  });
};

const initApp = () => {
  cssVarsPonyfill();

  new ModalsController();
  new ParallaxsController();
  new TweenersController();

  if (window['ga']) {
    window['ga']('send', 'pageview', location.pathname + location.search);
    window['ga']('set', 'page', location.pathname + location.search);
  }

  // if ($$('[data-header-observer]')[0]) {
  //   new IntersectionObserver((entries: any) => {
  //     const { isIntersecting } = entries[0];
  //     document.body.classList[isIntersecting ? 'remove' : 'add']('header-narrow');
  //   }).observe($$('[data-header-observer]')[0]);
  // }

  if ($$('[data-player]')[0]) [].forEach.call($$('[data-player]'), $p => new Player($p));
};

const onLoad = () => {
  turbolinks.start();
  initApp();
  setAnchors();
  new CookiebarJS();

  if ($$('[data-navicon]')[0] && $$('[data-nav]')[0]) {
    [].forEach.call($$('[data-navicon]'), ($navicon: HTMLElement) => {
      $navicon.addEventListener('click', () => {
        document.body.classList[navOpen ? 'remove' : 'add']('nav-open');
        navOpen = !navOpen;
        navOpen ? navTimeline.play() : navTimeline.reverse();
        navOpen ? fixBody() : releaseBody();
      });
    });
  }

  window['dataLayer'] = window['dataLayer'] || [];
  window['dataLayer'].push('js', new Date());
  window['dataLayer'].push('config', 'UA-60014781-2');
};

const beforeVisit = e => {
  setAnchors(e.data.url);
  navOpen = false;
  if (navTimeline) navTimeline.reverse();
  releaseBody();
};

document.addEventListener('turbolinks:before-visit', beforeVisit);

document.addEventListener('turbolinks:load', initApp);

window.addEventListener('load', onLoad);
