import gsap from 'gsap';

import * as Cookies from 'js-cookie';

import $$ from '../toolkit/$$';

class CookieBar {
  $bar: HTMLElement;
  $accepts: NodeListOf<HTMLElement>;
  hasCookie: boolean;
  animation: boolean;
  constructor() {
    if ($$('[data-cookiebar]')[0]) {
      this.$bar = $$('[data-cookiebar]')[0];
      this.$accepts = $$('[data-cookiebar-accept]');
      this.hasCookie = Boolean(Cookies.get('cookiebar'));
      this.setCookie = this.setCookie.bind(this);
      setTimeout(() => this.checkCookie(), 4000);
    }
    return;
  }

  checkCookie() {
    if (this.hasCookie) {
      this.$bar.parentElement.removeChild(this.$bar);
    } else {
      document.body.classList.add('has-cookie');
      this.$bar.classList.add('is-active');
      gsap.set(this.$bar, { display: 'block' });
      gsap.from(this.$bar, { y: '150%', ease: 'elastic.out(.75, 0.5)', duration: 1 });
    }
    if (this.$accepts) [].forEach.call(this.$accepts, $a => ($a.onclick = this.setCookie));
  }

  setCookie() {
    Cookies.set('cookiebar', 1);
    document.body.classList.remove('has-cookie');
    this.$bar.classList.remove('is-active');
    gsap.to(this.$bar, {
      y: '150%',
      duration: 1,
      onComplete: () => this.$bar.parentElement.removeChild(this.$bar),
    });
  }
}

export default CookieBar;
