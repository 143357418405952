import Tweener from './Tweener';
// import { gsap } from 'gsap';

class TweenerCountup extends Tweener {
  public addTweens() {
    const $countup = this.$tweener.querySelector('[data-tweener-countup]') as HTMLElement;
    const countTo = $countup.dataset.tweenerCountup ? Number($countup.dataset.tweenerCountup) : 0;
    let counter = { val: 0 };

    this.timeline.to(counter, {
      val: countTo,
      duration: 5,
      onUpdate: () => ($countup.innerHTML = Math.round(counter.val).toString()),
    }, 0);

    // this.timeline.play();

    return this;
  }

  public onTimelineComplete() {
    console.log('komplete');
    return this;
  }
}

export default TweenerCountup;
