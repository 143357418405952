import { gsap } from 'gsap';

import Modal from './Modal';

class ModalDefault extends Modal {
  onOpen() {
    gsap
      .timeline({
        defaults: { ease: 'power4.out' },
        onComplete: () => this.afterOpen(),
      })
      .set(this.$modal, { visibility: 'visible' })
      .fromTo(this.$modal, { opacity: 0 }, { opacity: 1, duration: 0.6 });
    return this;
  }

  afterClose() {
    this.$modal.style.visibility = 'hidden';
    this.isActive = false;
    return this;
  }
}

export default ModalDefault;
