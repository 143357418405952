import Swiper from 'swiper';

import ModalDefault from './Modal.Default';

class ModalGallery extends ModalDefault {
  galleryIndex: number = 0;
  slider: any;
  $sliderTarget: HTMLElement;

  public handleTriggerClick(e) {
    this.galleryIndex = Number(e.target.closest('[data-modal-index]').dataset.modalIndex);
    this.open();
    return this;
  }

  beforeMount() {
    this.$sliderTarget = this.$modal.querySelector('[data-modal-slider]');
    this.slider = new Swiper(this.$sliderTarget, {
      loop: true,
      speed: 500,
      simulateTouch: false,
      navigation: {
        nextEl: '[data-modal-slider-next]',
        prevEl: '[data-modal-slider-prev]',
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    });
    return this;
  }

  beforeOpen() {
    if (this.slider) this.slider.slideTo(this.galleryIndex + 1, 0);
    return this;
  }
}

export default ModalGallery;
