import Tweener from './Tweener';

class TweenerDefault extends Tweener {
  public addTweens() {
    this.timeline.from(this.$tweener, { y: -80, duration: 1 });
    return this;
  }
}

export default TweenerDefault;
